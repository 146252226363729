import React, { useState, useEffect } from 'react'
import { head, filter, map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import { Separator } from 'components/layout/Separator'
import { DropzoneArea } from 'material-ui-dropzone'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Error } from 'components/layout/Error'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { companyFileFieldMappingList } from './actions/contact_donor_parcel_field_mapping'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { CompanyFileImporter } from '../importer/CompanyFileImporter'
import {
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  Button,
  TextField,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import {Formik, Form, Field} from 'formik'
import { contactDonorParcelCheckProductsFile } from './actions/contact_donor_parcel'
import { donorParcelFieldMappingList } from 'portals/donor/actions/contact_dropdown_options'
import HelpText from 'components/HelpText'

const useStyles = makeStyles({
    root: {
        padding: 15
    },
    alert: {
      marginBottom: 10
    },
    saveButton: {
      marginTop: 10,
      marginBottom: 10,
    }
})

export default function DonateFoodImport({order_sheet_file_info_id, onImport}) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const parcel_field_mapping_options = useSelector(() => donorParcelFieldMappingList.getAsOptions())

    useEffect(() => {
        dispatch(donorParcelFieldMappingList.updatePaginationNumItemsPerPage(1000))
        dispatch(donorParcelFieldMappingList.fetchListIfNeeded())
    }, [])

    const helpText = <ol style={{marginLeft: 15}}>
                      <li>If you haven't already, <a onClick={() => history.push('/donor/settings/company_products')}>click here</a> to upload your list of products<br />
                      <Typography variant="caption">This will make mapping your donations to products easier going forwards</Typography></li>
                      <li>
                        Upload your donation sheet - this lists the food items you wish to donate.
                        <Typography variant="caption">
                          <p>We recommend using our excel template. <a href="/static/downloads/excel/foodspace-donation-template.xlsx">Click here</a> to download it.</p>
                        </Typography>
                      </li>

                      <li>Schedule a collection date.</li>
                      <li>Choose if we should collect or if you will deliver</li>
                      <li>Upload an invoice for this donation if you would like a Section18A receipt to be generated upon completion</li>
                    </ol>


    return(
        <>
          <HelpText
            title="Steps"
            text={helpText}
            severity="info"
          />

          <CompanyFileImporter
              field_mapping_type='donor_parcel_upload'
              field_mapping_options={parcel_field_mapping_options}
              sampleFileUrl="/static/downloads/excel/foodspace-donation-template.xlsx"
              checkFile={contactDonorParcelCheckProductsFile.checkFile}
              importFile={(file_info_id) => onImport(file_info_id)}
          />

        </>
    )
}
