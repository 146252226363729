import React, { useEffect, useState } from "react"
import { map, capitalize, size } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { contactCompanyList } from 'actions/contact/company'
import { ListItem, ListItemText, makeStyles, LinearProgress } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { whiteLabelCompanyList } from 'actions/white_label_company'
import BusyMask from 'components/BusyMask'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  loading: {
    margin: 15,
    color: '#777'
  }
}))

const ContactCompaniesList = ({onSelectCompany}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const contact_companies = useSelector(() => contactCompanyList.getVisibleObjects({can_login: true}))
  const company_name = whiteLabelCompanyList.getName()

  useEffect(() => {
    dispatch(contactCompanyList.updateListFilter({can_login: true}))
    dispatch(contactCompanyList.fetchListIfNeeded())
    dispatch(whiteLabelCompanyList.ensureWhiteLabelCompanyIsLoaded())
  },[])

  const renderCompanies = () => {
    if(size(contact_companies) > 0) {
      return map(contact_companies, (company) => {
        return(
          <>
            {company.name !== { company_name } &&

                <ListItem button className={classes.nested} onClick={() => onSelectCompany(company)} key={company.slug}>
                  <a href={`/${company.company_type}/${company.name}`}>
                    <ListItemText primary={`${company.name} ${capitalize(company.company_type)} Portal`} />
                  </a>
                </ListItem>

            }
          </>
        )
      })
    }
    else {
      return <div className={classes.loading}>Loading companies...<LinearProgress /></div>
    }
  }

  const companies = renderCompanies()

  if (whiteLabelCompanyList.isLoading()) {
    return <BusyMask />
  }

  return companies
}

export default ContactCompaniesList
