import React, { useState, useEffect } from 'react'
import { head, filter, map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import { Separator } from 'components/layout/Separator'
import { DropzoneArea } from 'material-ui-dropzone'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Error } from 'components/layout/Error'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { CompanyFileImporter } from '../importer/CompanyFileImporter'
import {
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  Button,
  TextField,
  IconButton,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import PageTitle from '../layout/PageTitle'
import {Formik, Form, Field} from 'formik'
import { contactCompanyProductList, contactDonorProductCheckProductsFile } from './actions/company_products'
import { donorProductFieldMappingList } from 'portals/donor/actions/contact_dropdown_options'
import Alert from '@material-ui/lab/Alert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles({
    root: {
        padding: 15
    },
    alert: {
      marginBottom: 10
    },
    saveButton: {
      marginTop: 10,
      marginBottom: 10,
    }
})

export const CompanyProductsImport = ({}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const [showHelp, setShowHelp] = useState(true)
    const product_field_mapping_options = useSelector(() => donorProductFieldMappingList.getAsOptions())

    useEffect(() => {
        dispatch(donorProductFieldMappingList.updatePaginationNumItemsPerPage(1000))
        dispatch(donorProductFieldMappingList.fetchListIfNeeded())
    }, [])

    const onImportFile = (file_info_id) => {

        const on_ok = (res) => {
            if ( res.status != 'success' ) {
                showError("Failed to import", res.error)
            } else {
                showSuccess("Imported", "Company product list updated")
                dispatch(contactCompanyProductList.invalidateList())
                dispatch(contactCompanyProductList.fetchListIfNeeded())
                history.push("/donor/settings/company_products/")
            }
        }
        dispatch(contactCompanyProductList.saveNewObject({products_file_info: file_info_id})).then(on_ok)
    }

    const renderHelpText = () => {
      return(
        <Alert severity="info" onClose={() => setShowHelp(false)} style={{marginBottom: 10}}>
          <Typography variant="h6">Steps</Typography>
          <Typography>
            <ol style={{marginLeft: 15}}>
              <li>Upload your excel list of products<br />
                <Typography variant="caption">
                  <p><a href="/static/downloads/excel/foodspace-product-template.xlsx">Click here to download an Excel template.</a></p>
                </Typography>
              </li>
              <li>Click the "Check Uploaded File" button</li>
              <li>If necessary, map the excel columns to our product columns.</li>
              <li>If there are errors, you must fix the errors before you proceed.</li>
            </ol>
          </Typography>
        </Alert>
      )
    }

    const renderHelpIcon = () => {
      return (
        <IconButton onClick={() => setShowHelp(true)}><HelpOutlineIcon /></IconButton>
      )
    }

    return(
        <DonorMainLayout
          active_key="donate_product"
          breadcrumbs={[
              {name: 'donor_home'},
              {name: 'products', label: "Products", url: '/donor/settings/company_products/'},
              {name: 'import', label: "Import", url: '/donor/settings/company_products/import'}
          ]}
          title={"Products"}
        >
          { showHelp && renderHelpText() }
          {!showHelp && renderHelpIcon()}

          <CompanyFileImporter field_mapping_type='donor_product_upload'
                               field_mapping_options={product_field_mapping_options}
                               sampleFileUrl="/static/downloads/excel/foodspace-product-template.xlsx"
                               checkFile={contactDonorProductCheckProductsFile.checkFile}
                               importFile={onImportFile}
          />

        </ DonorMainLayout>
    )
}
