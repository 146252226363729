import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { get, size, clone, tail } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { adminWarehouseProductAutoCompleteList } from 'admin/components/inventory/actions/admin_warehouse_product'
import { adminParcelList } from 'admin/components/orders/actions/admin_parcel'
import { WarehouseProductQuantityTypeFilter } from 'admin/components/filters/WarehouseProductQuantityTypeFilter'
import {
  TextField,
  Button,
  IconButton,
  Badge,
  Grid,
  makeStyles,
  Hidden,
  Typography,
  Chip,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import { Timestamp } from 'components/Timestamp'
import { CommonTable } from 'components/CommonTable'
import { Modal } from 'components/layout/Modal'
import FormikOnChange from 'components/form/FormikAutoSave'
import { ProductCategorySelectField } from 'admin/components/product_categories/form/ProductCategorySelectField'
import { HierarchyCrumbs } from 'components/layout/HierarchyCrumbs'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import NumberFormat from 'react-number-format'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import AdminBeneficiaryParcelCart from 'admin/components/orders/AdminBeneficiaryParcelCart'
import { whiteLabelCompanyList } from 'actions/white_label_company'
import BusyMask from 'components/BusyMask'

let drawerWidth = 320

const useStyles = makeStyles( ( theme ) => ( {
  root: {
    display: 'flex',
  },
  treeContainer: {
    flexGrow: 1,
    transition: theme.transitions.create( 'margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    } ),
    marginLeft: -drawerWidth,
  },
  treeContainerShift: {
    transition: theme.transitions.create( 'margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    } ),
    marginLeft: 0,
  },
  category_select_filter: {
    [ theme.breakpoints.down( 'sm' ) ]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 0,
      width: '100%'
    },
    [ theme.breakpoints.up( 'md' ) ]: {
      marginLeft: 15
    },
    [ theme.breakpoints.up( 'lg' ) ]: {

    },
  },
  filter_stock_availability: {
    marginLeft: 10,
    [ theme.breakpoints.up( 'md' ) ]: {
    },
  },
  cartIcon: {
    marginRight: 25,
    marginTop: -5
  }
} ) )

export function FormikWarehouseProductCart( { parcel_id, initialCartItems, onSave, onCancel, ...props } ) {
  const [ editingWarehouseProduct, setEditingWarehouseProduct ] = useState( null )
  const [ cartItems, setCartItems ] = useState( initialCartItems )
  const [ currentQuantityValue, setCurrentQuantityValue ] = useState( 0 )
  const [ initialValues, setInitialValues ] = useState( initialCartItems )
  const classes = useStyles()
  const dispatch = useDispatch()
  const is_loading = useSelector( () => adminWarehouseProductAutoCompleteList.isLoading() )
  const parcel = adminParcelList.getObject( parcel_id )
  const [ cartDrawerOpen, setCartDrawerOpen ] = useState( true )
  const primary_colour_hex = whiteLabelCompanyList.getPrimaryColourHex()

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
    dispatch(whiteLabelCompanyList.ensureWhiteLabelCompanyIsLoaded())
  }, [] )

  useEffect( () => {
    setInitialValues( cartItems )
  }, [ cartItems ] )

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminParcelList.ensureObjectLoaded( parcel_id ) )
    }
    fetchStaticObjects()
    setInitialValues( {} )
  }, [ parcel_id ] )

  useEffect( () => {
    async function fetchStaticObjects() {
      dispatch( adminWarehouseProductAutoCompleteList.updateListFilter( { warehouse: parcel.depart_warehouse } ) )
      dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
    }
    fetchStaticObjects()
  }, [ parcel ] )


  const handleDrawerClose = () => {
    setCartDrawerOpen( false )
  }

  const warehouse_products = useSelector( () => adminWarehouseProductAutoCompleteList.getVisibleObjects() )

  const columns = [
    {
      field: 'product_category_name',
      title: 'Product',
      render: ( item ) => get( item, "product_category_crumbs", [] )[ 0 ],
      visible: true,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_category_name' : '-product_category_name' ),
    },
    {
      field: 'product_category_name',
      title: 'Category',
      render: ( item ) => <HierarchyCrumbs crumbs={ tail( get( item, "product_category_crumbs", [] ) ) } />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'product_category_name' : '-product_category_name' ),
    },
    {
      field: 'warehouse_name',
      title: 'Warehouse',
    },
    {
      field: 'from_company_name',
      title: 'Donor',
      render: ( item ) => get( item, "from_company_name" ),
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'from_company_annotation' : '-from_company_annotation' ),
    },
    {
      field: 'best_before_date',
      title: 'Best before date',
      render: ( item ) => <Timestamp value={ get( item, "best_before_date" ) } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'best_before_date' : '-best_before_date' ),
    },
    {
      field: 'expiry_date',
      title: 'Expiry date',
      render: ( item ) => <Timestamp value={ get( item, "expiry_date" ) } format='date' />,
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'expiry_date' : '-expiry_date' ),
    },
    {
      field: 'product_weight_kg',
      title: 'Item Weight',
      visible: true,
      width: 150,
      render: ( item ) => <NumberFormat value={ get( item, [ "product_weight_kg" ] ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
      sort: ( direction ) => this.onUpdateListOrdering( direction === 'asc' ? 'product_weight_kg' : '-product_weight_kg' ),
    },
    {
      field: 'quantity',
      title: 'Available',
      visible: true,
      width: 100,
      align: 'right',
      sort: ( direction ) => onUpdateListOrdering( direction === 'asc' ? 'quantity' : '-quantity' ),
    },
    {
      field: 'select_quantity',
      title: 'Quantity',
      visible: true,
      width: 170,
      render: ( item ) => renderQuantityInput( item, primary_colour_hex ),
    },
    {
      field: 'total_weight',
      title: 'Weight',
      visible: true,
      width: 100,
      render: ( item ) => <NumberFormat value={ get( item, [ "product_weight_kg" ] ) * getCartItemQuantity( item ) } suffix={ "kg" } displayType={ 'text' } thousandSeparator={ true } decimalScale={ 2 } />,
    },
  ]

  const columnsMobile = [
    {
      field: 'select_quantity',
      title: 'Quantity',
      visible: true,
      width: 170,
      render: ( item ) =>
        <Grid container spacing={ 0 } justifyContent="space-between" alignItems="center">
          <Grid item xs={ 12 }>
            <Typography>{ item.product_category_name }</Typography>

          </Grid>
          <Grid item xs={ 6 }>
            <Chip label={ item.quantity } />&nbsp;
            <Typography variant="caption">@ { item.product_weight_kg }kg/item</Typography>
          </Grid>
          <Grid item xs={ 6 }>
            { renderQuantityInput( item, primary_colour_hex ) }
          </Grid>
        </Grid>,
    },
  ]

  const onUpdateListOrdering = ( field ) => {
    dispatch( adminWarehouseProductAutoCompleteList.updateListOrdering( field ) )
  }

  const startEditingWarehouseProduct = ( warehouse_product ) => {
    const quantity = getCartItemQuantity( warehouse_product )
    setEditingWarehouseProduct( warehouse_product )
    setCurrentQuantityValue( quantity )
  }

  const stopEditingWarehouseProduct = () => {
    setEditingWarehouseProduct( null )
  }

  const renderQuantityInput = ( warehouse_product, primary_colour_hex ) => {
    const is_editing = get( editingWarehouseProduct, "id" ) == get( warehouse_product, "id" )
    const quantity = getCartItemQuantity( warehouse_product )

    const setValue = ( e ) => {
      setCurrentQuantityValue( e.target.value )
    }

    return (
      <>
        { is_editing &&
          <>
            <TextField
              value={ currentQuantityValue }
              margin="dense"
              variant="outlined"
              autoFocus={ true }
              fullWidth={ false }
              style={ { width: 60 } }
              onChange={ setValue }
            />
            <Button
              variant="contained"
              style={ { background: primary_colour_hex, margin: '9px 0 0 5px' } }
              onClick={ () => updateCartItem( { warehouse_product, quantity: currentQuantityValue } ) }
            >
              <AddShoppingCartIcon />
            </Button>
          </>
        }
        { !is_editing &&
          <>
            <TextField
              value={ quantity }
              margin="dense"
              variant="outlined"
              fullWidth={ false }
              style={ { width: 60 } }
              onFocus={ () => startEditingWarehouseProduct( warehouse_product ) }
            />
          </>
        }
      </>
    )
  }

  const handleSave = () => {
    onSave( cartItems )
  }

  const updateCartItem = ( { warehouse_product, quantity } ) => {
    const newCartItems = clone( cartItems )
    newCartItems[ warehouse_product.id ] = { warehouse_product: warehouse_product, quantity: quantity }
    console.log( "warehouse_product", warehouse_product )
    setCartItems( newCartItems )
    stopEditingWarehouseProduct()
  }

  const getCartItemQuantity = ( warehouse_product ) => {
    return get( cartItems, [ warehouse_product.id, "quantity" ] )
  }

  const onFilterChanged = ( values, formik_props ) => {
    dispatch( adminWarehouseProductAutoCompleteList.updateListFilter( values ) )
    dispatch( adminWarehouseProductAutoCompleteList.fetchListIfNeeded() )
  }

  const renderFilter = () => {
    return (
      <Formik
        initialValues={ initialValues }
        onSubmit={ onFilterChanged }
        enableReinitialize={ true }
      >
        { formik_props => {
          const { values } = formik_props
          return (
            <Form>
              <FormikOnChange onChange={ ( values ) => onFilterChanged( values, formik_props ) } />
              {/* <FormikGeneralFormErrors render={(msg) => msg} /> */ }
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <div className={ classes.filter_stock_availability }>
                    <WarehouseProductQuantityTypeFilter
                      name="quantity_type"
                      formik_props={ formik_props }
                    />
                  </div>
                </Grid>
                <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
                  <ProductCategorySelectField
                    name="product_category"
                    can_add={ false }
                    can_edit={ false }
                    formik_props={ formik_props }
                  />
                </Grid>


              </Grid>
            </Form>
          )
        } }
      </Formik>
    )
  }

  const renderCarticon = () => {
    return (
      <IconButton onClick={ () => setCartDrawerOpen( !cartDrawerOpen ) } className={ classes.cartIcon }>
        <Badge badgeContent={ size( cartItems ) } color="secondary">
          <ShoppingCartOutlinedIcon fontSize="large" />
        </Badge>
      </IconButton>
    )
  }

  const renderWarehouseProductsTable = () => {
    return (
      <>
        <Hidden smDown>
          <CommonTable
            is_loading={ is_loading }
            rows={ warehouse_products }
            canDelete={ false }
            columns={ columns }
            item_list={ adminWarehouseProductAutoCompleteList }
            useColumnsSelector={ true }
            storeTableName={ "adminWarehouseProductAutoCompleteList" }
            loadingVariant="linear"
          />
        </Hidden>
        <Hidden mdUp>
          <CommonTable
            is_loading={ is_loading }
            rows={ warehouse_products }
            canDelete={ false }
            columns={ columnsMobile }
            item_list={ adminWarehouseProductAutoCompleteList }
            useColumnsSelector={ true }
            storeTableName={ "adminWarehouseProductAutoCompleteList" }
            loadingVariant="linear"
            mobile
          />
        </Hidden>
      </>
    )
  }

  if (whiteLabelCompanyList.isLoading()) {
    return <BusyMask />
  }

  return (
    <Modal
      onClose={ onCancel }
      onSave={ handleSave }
      fullScreen
      title="Select Products from Inventory"
      noPadding
      extraAppBarIcons={ renderCarticon() }
    >
      <div className={ classes.root }>
        <AdminBeneficiaryParcelCart
          cartItems={ cartItems }
          open={ cartDrawerOpen }
          handleDrawerClose={ handleDrawerClose }
          drawerWidth={ drawerWidth }
          is_loading={ is_loading }
        />
        <div className={ clsx( classes.treeContainer, { [ classes.treeContainerShift ]: cartDrawerOpen } ) }>
          { renderFilter() }
          { renderWarehouseProductsTable() }
        </div>
      </div>
    </Modal>
  )
}
