import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, map, size } from 'lodash'
import { Grid, FormHelperText, Typography, Button, Collapse } from '@material-ui/core'
import { adminDonorList, adminDonorAddressManager } from 'admin/components/donors/actions/admin_donor'
import { FormikDonorAutoCompleteDropdown } from 'admin/components/donors/form/DonorAutoCompleteDropdown'
import { FormikWarehouseAutoCompleteDropdown } from 'admin/components/warehouses/form/WarehouseAutoCompleteDropdown'
import { FormikDonorAddressDropdownWithFormBasedAddNew } from 'admin/components/donors/form/DonorAddressDropdown'
import { FormikDateTimePicker } from 'components/form/DatetimePicker'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikInputField } from 'components/form/InputField'
import OrderTags from './OrderTags'
import { FormikSwitchField } from '../../../../components/form/SwitchField'
import { whiteLabelCompanyList } from 'actions/white_label_company'
import BusyMask from 'components/BusyMask'

const DonorGeneralOrderForm = ( { formik_props } ) => {
  const dispatch = useDispatch()
  const donor = useSelector( () => adminDonorList.getForCompany( { company_id: get( formik_props, [ "values", "from_company" ] ) } ) )
  const transport_by_warehouse_company = get( formik_props.values, "transport_by_warehouse_company" )
  const address_ids = get( donor, [ "company", "addresses" ] )
  const schedule_helper_action = transport_by_warehouse_company ? 'we be picking up' : 'they be delivering'
  const [ showTransportCheckbox, setShowTransportCheckbox ] = useState( false )
  const company_name = whiteLabelCompanyList.getName()

  useEffect(() => {
    dispatch(whiteLabelCompanyList.ensureWhiteLabelCompanyIsLoaded())
  }, [])

  const renderFieldAdvice = ( text, subText ) => {
    const fontSize = subText ? 24 : 36
    const margin = subText ? '0 0 15px 0' : '15px 0'
    const lines = text.split( '\n' ) // Split the text into lines based on the newline character
    return (
      <Typography variant="h2" color="primary" style={ { fontSize: fontSize, margin: margin } }>
        { map( lines, ( line, index ) => (
	  <React.Fragment key={ index }>
	  { line }
	  { index < size( lines ) - 1 && <br /> } {/* Add a line break except after the last line */ }
	  </React.Fragment>
        ) ) }
      </Typography>
    )
  }

  const renderDonorField = (
    <>
      { !formik_props.values.from_company &&
        renderFieldAdvice( "Select a donor" )
      }
      <FormikDonorAutoCompleteDropdown
        name="from_company"
        label="Donor"
        formik_props={ formik_props }
      />
    </>
  )

  const renderPickuporDeliveryAlert = (
    <div style={ { background: '#2196f3', padding: '7px 15px', borderRadius: 7, color: "white" } }>
      <Typography variant="h5">
        { formik_props.values.transport_by_warehouse_company ?
          `${ company_name } Pickup` :
          `${ formik_props.values.from_company_name ? formik_props.values.from_company_name : "Donor" } will Deliver`
        }
        <Button
          onClick={ () => setShowTransportCheckbox( !showTransportCheckbox ) }
          size="small"
          variant="contained"
          style={ { float: 'right' } }
          color="secondary"
        >
          Change?
        </Button>
      </Typography>
      { showTransportCheckbox &&
        <div>
          <FormikCheckboxField
            name="transport_by_warehouse_company"
            label={`${company_name} transport`}
            formik_props={ formik_props }
            color="primary"
            onClick={ () => setShowTransportCheckbox( !showTransportCheckbox ) }
          />
          <br />
          <FormHelperText>
            { formik_props.values.transport_by_warehouse_company ?
	      "(Uncheck for donor to deliver)" :
	      `(Check for ${ company_name } to provide transport)`
            }
          </FormHelperText>
        </div>
      }
    </div>
  )

  const renderDateTimeField = (
    <>
      { !formik_props.values.depart_at &&
        renderFieldAdvice( `What date and time will ${ schedule_helper_action } this donation?` )
      }
      <FormikDateTimePicker
        name="depart_at"
        label="Schedule Date & Time"
        formik_props={ formik_props }
        fullWidth={ false }
      />
    </>
  )

  const renderWarehouseField = (
    <>
      {
        !formik_props.values.arrive_warehouse &&
        renderFieldAdvice( "Select the warehouse that will be receiving this donation." )
      }
      <FormikWarehouseAutoCompleteDropdown
        name="arrive_warehouse"
        label="Receiving warehouse"
        formik_props={ formik_props }
      />
    </>
  )

  const renderDonorAddressField = (
    <>
      { !formik_props.values.depart_address &&
        <>
          { renderFieldAdvice( `Select the pickup address.` ) }
          { renderFieldAdvice( `The driver needs this to navigate to the pickup using the driver app.`, true ) }
        </>
      }
      <FormikDonorAddressDropdownWithFormBasedAddNew
        name="depart_address"
        donor={ donor }
        label="Pickup Address"
        address_ids={ address_ids }
        formik_props={ formik_props }
      />
    </>
  )

  const renderNotesAndTagsFields = (
    <>
      <FormikSwitchField
        name="show_order_notes"
        label="Any order notes?"
        formik_props={ formik_props }
        color="primary"
      />
      <FormikSwitchField
        name="show_order_tags"
        label="Any order tags?"
        formik_props={ formik_props }
        color="primary"
      />
      { formik_props.values.show_order_notes &&
        <FormikInputField
          name="description"
          label="Order notes..."
          multiline={ true }
          rows={ 4 }
          formik_props={ formik_props }
        />
      }
      { formik_props.values.show_order_tags &&
        <OrderTags formik_props={ formik_props } />
      }
    </>
  )

  if (whiteLabelCompanyList.isLoading()) {
    return <BusyMask/>
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        { renderDonorField }
        { renderPickuporDeliveryAlert }
      </Grid>
      <Grid item xs={ 12 }>
        <Collapse in={ formik_props.values.from_company && transport_by_warehouse_company && get( donor, "id" ) } >
          { renderDonorAddressField }
        </Collapse>
      </Grid>
      { formik_props.values.from_company &&
        <>
          <Grid item xs={ 12 }>
            <Collapse in={ formik_props.values.depart_address || !transport_by_warehouse_company } >
              { renderDateTimeField }
            </Collapse>
          </Grid>
          <Grid item xs={ 12 }>
            <Collapse in={ formik_props.values.depart_at } >
              { renderWarehouseField }
            </Collapse>
          </Grid>
          <Grid item xs={ 12 }>
            <Collapse in={ formik_props.values.arrive_warehouse } >
              { renderNotesAndTagsFields }
            </Collapse>
          </Grid>
        </>
      }
    </Grid>
  )
}

export default DonorGeneralOrderForm
