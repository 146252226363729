import React, { useState, useEffect } from 'react'
import { head, filter, map, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DonorMainLayout from '../layout/DonorMainLayout'
import { Separator } from 'components/layout/Separator'
import { DropzoneArea } from 'material-ui-dropzone'
import { FormikDropdownField } from 'components/form/Dropdown'
import { Error } from 'components/layout/Error'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import { MuiFileUploader } from 'components/form/MuiFileUploader'
import { CompanyFileImporter } from '../importer/CompanyFileImporter'
import { getActiveCompanyId } from 'actions/active_company'
import {
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  Button,
  TextField,
  IconButton,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core'
import { FormikGeneralFormErrors } from 'components/form/GeneralFormErrors'
import DatePicker from "react-datepicker"
import PageTitle from '../layout/PageTitle'
import {Formik, Form, Field} from 'formik'
import { contactCompanyList, contactCompanyCheckImportFile } from '../actions/contact_company'
import { companyBranchFieldMappingList } from 'portals/donor/actions/contact_dropdown_options'
import Alert from '@material-ui/lab/Alert'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HelpText from 'components/HelpText'

const useStyles = makeStyles({
    root: {
        padding: 15
    },
    alert: {
      marginBottom: 10
    },
    saveButton: {
      marginTop: 10,
      marginBottom: 10,
    }
})

export const CompanyBranchesImport = ({}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const [showHelp, setShowHelp] = useState(true)
    const branch_field_mapping_options = useSelector(() => companyBranchFieldMappingList.getAsOptions())

    useEffect(() => {
        dispatch(companyBranchFieldMappingList.updatePaginationNumItemsPerPage(1000))
        dispatch(companyBranchFieldMappingList.fetchListIfNeeded())
    }, [])

    const onImportFile = (file_info_id) => {

        const on_ok = (res) => {
            if ( res.status != 'success' ) {
                showError("Failed to import", res.error)
            } else {
                showSuccess("Imported", "Company branch list updated")
                dispatch(contactCompanyList.invalidateList())
                dispatch(contactCompanyList.fetchListIfNeeded())
                history.push("/donor/settings/company_branches/")
            }
        }
        dispatch(contactCompanyList.saveNewObject({parent: getActiveCompanyId(), file_info: file_info_id})).then(on_ok)
    }

    const helpText = <ol style={{marginLeft: 15}}>
                        <li>Upload your excel list of branches<br />
                          <Typography variant="caption">
                            <p><a href="/static/downloads/excel/foodspace-branch-template.xlsx">Click here to download an Excel template.</a></p>
                          </Typography>
                        </li>
                        <li>Click the "Check Uploaded File" button</li>
                        <li>If necessary, map the excel columns to our branch columns.</li>
                        <li>If there are errors, you must fix the errors before you proceed.</li>
                      </ol>

    return(
        <>
          <HelpText
            title="Steps"
            text={helpText}
            severity="info"
          />

          <CompanyFileImporter field_mapping_type='company_branch_upload'
                               field_mapping_options={branch_field_mapping_options}
                               sampleFileUrl="/static/downloads/excel/foodspace-branch-template.xlsx"
                               checkFile={ ({file_info}) => contactCompanyCheckImportFile.checkFile({parent_company_id: getActiveCompanyId(), file_info})}
                               importFile={onImportFile}
          />

        </>
    )
}
