import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get, map } from 'lodash'
import ConfirmationModal from 'components/ConfirmationModal'
import Breadcrumbs from 'components/layout/Breadcrumbs'
import { makeStyles, Grid } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Root, Header, Nav, Content, Footer } from './'
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from 'muiTheme'
import { ThemeProvider } from '@material-ui/core/styles';
import { Copyright } from 'components/Copyright'
import LoggedInUser from './header/LoggedInUser'
import config from 'config'
import { isLoggedIn, loggedInUser } from 'actions/auth'
import AdminMenu from './menu/AdminMenu'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import Alert from '@material-ui/lab/Alert'
import { setAdminLoggedInAlertRead, getAdminLoggedInAlertRead } from 'actions/active_company'
import { whiteLabelCompanyList } from 'actions/white_label_company'
import BusyMask from 'components/BusyMask'

const useStyles = makeStyles((theme) => ({
    content: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    title_actions_row: {
        display: "flex",
        justifyContent: "space-between"
    },
    logo: {
        width: 180,
        align: "center"
    },
    brandContainer: {
        background: ({ primary_colour_hex }) => primary_colour_hex,
        boxShadow: '0px 10px 10px -18px #111',
        padding: '10px 10px 5px 10px',
    },
    siteName: {
        marginTop: 32,
        fontSize: 17,
        fontWeight: 500
    },
    userMenu: {
        background: '#fafbfd',
        borderBottom: '1px solid #ebeff2',
    },
    title: {
        marginLeft: 35,
        fontSize: 24,
        padding: '15px 0'
    },
    adminAlert: {
        marginLeft: -15,
        marginRight: -15,
        borderRadius: 0,
        background: '#e53935',
        color: 'white',
        "& .MuiAlert-icon": {
            color: 'white'
        }
    },
    headerIcon: {
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 7,
            marginBottom: 5
        },
    },
    toolbar: {
        zIndex: 1
    }
}))

export default function AdminMainLayout({ title, children, breadcrumbs, actions, active_key }) {
    const dispatch = useDispatch()
    const user = loggedInUser()
    const [showAdminAlert, setShowAdminAlert] = React.useState(true)
    const logo_url = whiteLabelCompanyList.getLogoUrl()
    const primary_colour_hex = whiteLabelCompanyList.getPrimaryColourHex()
    const muiTheme = createMuiTheme(primary_colour_hex)
    const classes = useStyles({ primary_colour_hex })

    useEffect(() => {
        dispatch(whiteLabelCompanyList.ensureWhiteLabelCompanyIsLoaded())
    }, [])

    const onCloseAdminAlert = () => {
        setShowAdminAlert(false)
        setAdminLoggedInAlertRead(true)
    }

    const renderAdminEditAlert = () => {
        if(getAdminLoggedInAlertRead() === false) {
            return (
                <Alert
                    severity="error"
                    className={classes.adminAlert}
                    onClose={() => onCloseAdminAlert()}
                >
                    You are logged in as admin. You should not create or edit anything as you could really mess things up. Generating reports is fine.
                </Alert>
            )
        }
    }

    if (whiteLabelCompanyList.isLoading()) {
      return <BusyMask/>
    }

    return (
        <ThemeProvider theme={ muiTheme }>
            <Root config={config} style={{ minHeight: "100vh" }}>
                <CssBaseline />
                <Header
                    menuIcon={{
                        inactive: <MenuIcon className={classes.headerIcon} />,
                        active: <ChevronLeftIcon className={classes.headerIcon} />
                    }}
                />
                <Nav
                    collapsedIcon={{
                        inactive: <ChevronLeftIcon />,
                        active: <ChevronRightIcon />
                    }}
                    header={
                        ctx => null
                    }
                >

                    <Grid container className={classes.brandContainer}>
                        <Grid item >
                            <img src={ logo_url }
                                alt="Logo"
                                className={classes.logo}
                            />
                            <div>Admin Portal</div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.userMenu}
                    >
                        <Grid item><LoggedInUser /></Grid>
                    </Grid>

                    <SimpleBar style={{ maxHeight: '90vh' }}>
                        <AdminMenu />
                    </SimpleBar>
                </Nav>

                <Content className={classes.content}>
                    <ConfirmationModal />
                    <div className={classes.title}>{title && title}</div>
                    {showAdminAlert && get(user, 'is_superuser') && renderAdminEditAlert()}
                    <div className={classes.title_actions_row}>
                        <Breadcrumbs crumbs={breadcrumbs} />
                        <div>
                            {map(actions, (action) => action)}
                        </div>
                    </div>
                    {children}
                </Content>

                <Footer>
                    <Copyright />
                </Footer>

            </Root>

        </ThemeProvider>
    )
}
