import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { whiteLabelCompanyList } from 'actions/white_label_company'
import BusyMask from 'components/BusyMask'

export const Copyright = () => {
  const dispatch = useDispatch()
  const company_website = whiteLabelCompanyList.getWebsite()
  const company_name = whiteLabelCompanyList.getName()

  useEffect(() => {
    dispatch(whiteLabelCompanyList.ensureWhiteLabelCompanyIsLoaded())
  }, [])

  if (whiteLabelCompanyList.isLoading()) {
    return <BusyMask />
  }
  
  return (
    <Typography variant="caption" color="textSecondary" align="center">
      &copy;
      <Link color="inherit" href={ company_website }>{ company_name }</Link>{' '}
      {new Date().getFullYear()} {' - All rights reserved'}
    </Typography>
  )
}
