import { ItemList } from 'orm'
import { get, head } from 'lodash'

class WhiteLabelCompanyList extends ItemList {
  getEntityKey() {
    return "white_label_company"
  }

  ensureWhiteLabelCompanyIsLoaded() {
    const that = this
    return async (dispatch, getState) => {
      const company = that.getWhiteLabelCompany()
      const company_code = this.getWhiteLabelCompanyCode()
      if ( company_code && company && company_code != company.code ) {
        dispatch(that.invalidateObject(company.id))
        dispatch(that.ensureObjectLoaded(company.id))
      }
      dispatch(that.fetchListIfNeeded())
    }
  }

  getWhiteLabelCompany() {
    const company = head(this.getVisibleObjects())
    const company_code = this.getWhiteLabelCompanyCode()
    if ( company_code !== null && company && company.code != company_code) {
      console.log('No match', company_code, company)
    }
    return company
  }

  getWhiteLabelCompanyCode(props) {
    let company_code = get(props, ['match', 'params', 'company_code'], null)
    if ( company_code === null ) {
      company_code = get(window.location.host.split('.'), 0, null)
      if (company_code === 'web' || company_code === 'platform') {
        company_code = 'saharvest'
      }
    }
    return company_code
  }

  getLogoUrl(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'logo_url')
  }

  getLargeLogoUrl(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'large_logo_url')
  }

  getBannerUrl(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'banner_url')
  }

  getPrimaryColourHex(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'primary_colour_hex')
  }

  getName(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'name')
  }

  getWebsite(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'website')
  }

  getAdminEmail(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'admin_email')
  }

  getSupportEmail(company) {
    company = company || this.getWhiteLabelCompany()
    return get(company, 'support_email')
  }
}

export const whiteLabelCompanyList = new WhiteLabelCompanyList("white_label_company__default")
